import axios from 'axios';
import { generateHttpActions } from '../../utility';

function mySiteHealthBookmarkHttpRequest() {
  const httpActions = generateHttpActions('mySiteHealthBookmark');
  const resource = 'site-health/site-health-bookmark';

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource);
      dispatch(httpActions.success({ data: response }));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default mySiteHealthBookmarkHttpRequest;
