import React, { useEffect, useState, useCallback } from 'react';
import Error from 'components/Error';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import { compassStore } from 'store';
import { useThunkSelector } from 'utility';
import { mySiteHealthBookmarkHttpRequest } from 'store/thunks';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import mySiteHealthDeletePlaylistHttpRequest from 'store/thunks/06_mySiteHealthDeletePlaylist';
import { USER_PLAYLIST_SECTION_ID } from 'dashboards/SiteHealth/constants';
import { siteHealthStore } from 'dashboards/SiteHealth/store';
import { preferencesConfigAction } from 'dashboards/SiteHealth/store/actions';
import { SITE_HEALTH_MODULE } from 'constants/savedFilters/savedFilters.constants';
import './MySitesHealth.section.scss';

function MySiteHealthSection({ setSidebarVisible }) {
  const { dispatch } = compassStore;
  const { dispatch: siteHealthDispatch } = siteHealthStore;

  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState('');

  useEffect(() => {
    dispatch(mySiteHealthBookmarkHttpRequest());
  }, []);

  const setBlockPreferences = (type, value, sectionId) => {
    const preferencesConfig = { type, value, sectionId };
    siteHealthDispatch(preferencesConfigAction(preferencesConfig));
  };

  const playlistName = useThunkSelector('mySiteHealthBookmark');

  // Deletion of Playlist
  const handleDelete = useCallback((name) => {
    setSelectedPlaylist(name);
    setShowDialog(true);
  }, []);

  // Cancel of Dialog
  const onCancelCancelation = useCallback(() => {
    setShowDialog(false);
  }, []);

  const onConfirmation = useCallback(async () => {
    setIsLoading(true);
    setShowDialog(true);

    // Call delete playlist API request and wait for completion
    try {
      await mySiteHealthDeletePlaylistHttpRequest({
        dataTable: SITE_HEALTH_MODULE,
        playlistName: selectedPlaylist,
      });
      await dispatch(mySiteHealthBookmarkHttpRequest());

      setBlockPreferences('TOGGLE_SWITCH', false, USER_PLAYLIST_SECTION_ID);
      setBlockPreferences('SELECTED_LIST', '', USER_PLAYLIST_SECTION_ID);
    } catch (error) {
      setSidebarVisible(false);
    } finally {
      setIsLoading(false);
      setShowDialog(false);
    }
  }, [selectedPlaylist, dispatch]);

  return (
    <div className="row my-filters px-1">
      {playlistName.isError && <Error error={playlistName.error} />}
      {playlistName.isLoading && <Loader />}
      {playlistName.isSuccess && (
        <div>
          {playlistName.payload?.data?.data?.length > 0 &&
            playlistName.payload.data.data.map((element) => (
              <div className="playlist-item">
                <div className="playlist-item-content">
                  <h5 className="playlist-name">{element.Name}</h5>
                  <p
                    className="delete-icon"
                    onKeyDown={() => {
                      // Mock DATA
                    }}
                    onClick={() => handleDelete(element.Name)}
                  >
                    <i className="pi pi-trash" />
                  </p>
                </div>
                <div className="underline" />
              </div>
            ))}
          {playlistName.payload.data.data.length === 0 && (
            <div data-testid="create-bookmark">
              <p>Create a bookmark from Site Management</p>
            </div>
          )}
        </div>
      )}

      {showDialog && (
        <ConfirmationDialog
          className="loader-container"
          isLoading={isLoading}
          heading=""
          content={`You are deleting ${selectedPlaylist}`}
          onConfirmation={onConfirmation}
          onCancel={onCancelCancelation}
        />
      )}
    </div>
  );
}

MySiteHealthSection.propTypes = {
  setSidebarVisible: PropTypes.func,
};

MySiteHealthSection.defaultProps = {
  setSidebarVisible: () => {},
};

export default MySiteHealthSection;
