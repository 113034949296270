import { TIMELINE_ITEMS } from '../../../../../../constants';

const COMMON_TABLE_ITEMS = {
  actualVsBaselined: {
    code: 'actualVsBaselined',
    label: 'Actual vs Baselined plan difference (days)',
    type: 'CALCULATED',
  },
  actualVsCurrentPlan: {
    code: 'actualVsCurrentPlan',
    label: 'Actual vs Current plan difference (days)',
    type: 'CALCULATED',
  },
  baselinedVsCurrentPlan: {
    code: 'baselinedVsCurrentPlan',
    label: 'Baselined vs Current plan difference (days)',
    type: 'CALCULATED',
  },
  currentPlanVsActual: {
    code: 'currentPlanVsActual',
    label: 'Current vs Actual plan difference (days)',
    type: 'CALCULATED',
  },
  baselinedVsActual: {
    code: 'baselinedVsActual',
    label: 'Baselined vs Actual plan difference (days)',
    type: 'CALCULATED',
  },
};

export const TABLE_DATA_STRUCTURE = {
  baselinedMilestoneDate: {
    code: 'baselinedMilestoneDate',
    label: 'Baselined Plan',
    type: 'RAW',
  },
  plannedMilestoneDate: {
    code: 'plannedMilestoneDate',
    label: 'Current Plan',
    type: 'RAW',
  },
  actualMilestoneDate: {
    code: 'actualMilestoneDate',
    label: 'Actual',
    type: 'RAW',
  },
  currentPlanVsBaselined: {
    code: 'currentPlanVsBaselined',
    label: 'Current vs Baselined plan difference (days)',
    type: 'CALCULATED',
  },
  actualVsBaselined: {
    code: 'actualVsBaselined',
    label: 'Actual vs Baselined plan difference (days)',
    type: 'CALCULATED',
  },
  ...COMMON_TABLE_ITEMS,
};

export const COMPLEX_TABLE_DATA_STRUCTURE = {
  baselinedMilestoneDate: {
    code: 'baselinedMilestoneDate',
    label: TIMELINE_ITEMS.BASELINED,
    type: 'RAW',
  },
  plannedMilestoneDate: {
    code: 'plannedMilestoneDate',
    label: TIMELINE_ITEMS.PLANNED,
    type: 'RAW',
  },
  actualMilestoneDate: {
    code: 'actualMilestoneDate',
    label: TIMELINE_ITEMS.ACTUAL_AND_FORECAST,
    type: 'RAW',
  },
  currentPlanVsBaselined: {
    code: 'currentPlanVsBaselined',
    label: 'Current vs Baselined plan difference (days)',
    type: 'CALCULATED',
  },
  ...COMMON_TABLE_ITEMS,
};
