import { get, orderBy } from 'lodash';

function crfVerifySortTransform(tableData) {
  return {
    table: orderBy(
      tableData.values,
      (tableRow) => get(tableRow, 'siteName', null),
      'asc',
    ),
    source: tableData.cardList,
  };
}

export default crfVerifySortTransform;
