export const KEY_FACTS_SECTION_ID = 'KEY_FACTS_SECTION_ID';
export const ENROLMENT_CURVE_SECTION_ID = 'ENROLMENT_CURVE';
export const GLOBAL_FILTER_SECTION_ID = 'GLOBAL_FILTER_SECTION';
export const USER_TRIALS_FILTER_SECTION_ID = 'USER_TRIALS_FILTER';
export const TRIAL_MANAGEMENT_NAVIGATION_ID = 'TRIAL_MANAGEMENT_NAVIGATION_ID';

export const ACTIVATION_AND_ENROLLMENT_SECTION_ID = 'ACTIVATION_AND_ENROLLMENT';

export const ENROLLMENT_DRIVERS = 'ENROLLMENT_DRIVERS';
export const ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREENING =
  'ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREENING';
export const ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREEN_FAILURE =
  'ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREEN_FAILURE';
export const ENROLLMENT_DRIVERS_CURRENT_NUMBER_DROPOUT =
  'ENROLLMENT_DRIVERS_CURRENT_NUMBER_DROPOUT';

export const ENROLLMENT_DRIVERS_RATE_SCREENING =
  'ENROLLMENT_DRIVERS_RATE_SCREENING';
export const ENROLLMENT_DRIVERS_RATE_SCREEN_FAILURE =
  'ENROLLMENT_DRIVERS_RATE_SCREEN_FAILURE';
export const ENROLLMENT_DRIVERS_RATE_DROPOUT =
  'ENROLLMENT_DRIVERS_RATE_DROPOUT';

export const MILESTONES_SECTION_ID = 'ENROLLMENT_MILESTONES';

export const COMPLEX_MILESTONES_SECTION_ID = 'ENROLLMENT_COMPLEX_MILESTONES';

export const CYCLE_TIMES_SECTION_ID = 'CYCLE_TIMES_SECTION';

export const PROTOCOL_AMENDMENT_SECTION_ID = 'PROTOCOL_AMENDMENT_SECTION';

export const TRIAL_DIVERSITY_SECTION_ID = 'TRIAL_DIVERSITY_SECTION';

export const TRIAL_CARD_VIEW_SECTION_ID = 'TRIAL_CARD_VIEW_SECTION_ID';

export const COMPLEX_MILESTONES_LABELS = {
  NEXT_MILESTONES_STATUS: 'NEXT_MILESTONES_STATUS',
};

export const EDC_READINESS_SECTION_ID = 'EDC_READINESS_SECTION';

export const TRIAL_VIEW_TYPE = {
  CARD: 'Card',
  LIST: 'List',
  MILESTONE: 'Milestones',
  GPT: 'GPT trial delivery goals',
};

export const DATA_TABLE_SERIES_VISIBILITY_OPTIONS = [
  { value: 'baselined', label: 'Baselined Plan' },
  { value: 'planned', label: ' Current Plan' },
  { value: 'actual', label: ' Actual' },
];

export const DATA_TABLE_FORECAST_OPTIONS = [
  { value: 'forecast', label: ' Forecast' },
  { value: 'probability', label: ' Probability range' },
];

export const TRIAL_DELIVERY_GOALS_SECTION_ID = {
  MILESTONE: 'TRIAL_DELIVERY_GOALS_SECTION_ID_MILESTONE',
  GPT: 'TRIAL_DELIVERY_GOALS_SECTION_ID_GPT',
};

export const SITE_LEADERBOARD_SECTION_ID = 'SITE_LEADERBOARD_SECTION';

export const TRIAL_MANAGEMENT_CHAT_CONTAINER =
  'TRIAL_MANAGEMENT_CHAT_CONTAINER_SECTION';

export const searchKeys = [
  'protocolNumberHarmonized',
  'countries',
  'productName',
  'indication',
  'cro',
  'tau',
];
