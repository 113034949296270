import { UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import { cloneDeep, isNil, map } from 'lodash';
import DateStringTransform from 'utility/date/dateString.utility';

const getDifferenceBetweenDatesInDays = (dateString1, dateString2) => {
  if (!dateString1 || !dateString2) {
    return null;
  }
  const date1 = new Date(`${dateString1}T00:00:00.000Z`);
  const date2 = new Date(`${dateString2}T00:00:00.000Z`);
  return Math.floor((date1 - date2) / (60 * 60 * 24 * 1000));
};

const formatDate = (dateString) => {
  if (!dateString) {
    return null;
  }
  const formattedDate = new DateStringTransform(dateString).formatter(
    UI_DISPLAY_DATE,
  );
  return formattedDate;
};

const transformTableData = (data, tableStructure) => {
  const { timelineItems } = data;
  const records = cloneDeep(tableStructure);
  const filteredTimelineItems = timelineItems.filter(
    (record) => !isNil(record.milestoneName),
  );
  const headers = ['label', ...map(filteredTimelineItems, 'milestoneName')];

  filteredTimelineItems.forEach((timelineItem) => {
    const { milestoneName, isMilestoneAchieved } = timelineItem;

    records.baselinedMilestoneDate[milestoneName] = formatDate(
      timelineItem.baselinedMilestoneDate,
    );
    records.plannedMilestoneDate[milestoneName] = formatDate(
      timelineItem.plannedMilestoneDate,
    );
    records.actualMilestoneDate[milestoneName] = {
      date: formatDate(timelineItem.actualMilestoneDate),
      isMilestoneAchieved,
    };
    records.currentPlanVsBaselined[milestoneName] =
      getDifferenceBetweenDatesInDays(
        timelineItem.plannedMilestoneDate,
        timelineItem.baselinedMilestoneDate,
      ) ?? '-';
    records.actualVsBaselined[timelineItem.milestoneName] =
      getDifferenceBetweenDatesInDays(
        timelineItem.actualMilestoneDate,
        timelineItem.baselinedMilestoneDate,
      ) ?? '-';

    records.actualVsCurrentPlan[timelineItem.milestoneName] =
      getDifferenceBetweenDatesInDays(
        timelineItem.actualMilestoneDate,
        timelineItem.plannedMilestoneDate,
      ) ?? '-';
    records.baselinedVsCurrentPlan[timelineItem.milestoneName] =
      getDifferenceBetweenDatesInDays(
        timelineItem.baselinedMilestoneDate,
        timelineItem.plannedMilestoneDate,
      ) ?? '-';

    records.currentPlanVsActual[timelineItem.milestoneName] =
      getDifferenceBetweenDatesInDays(
        timelineItem.plannedMilestoneDate,
        timelineItem.actualMilestoneDate,
      ) ?? '-';
    records.baselinedVsActual[timelineItem.milestoneName] =
      getDifferenceBetweenDatesInDays(
        timelineItem.baselinedMilestoneDate,
        timelineItem.actualMilestoneDate,
      ) ?? '-';
  });

  const tableData = { headers, records };
  return tableData;
};

export default transformTableData;
