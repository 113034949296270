import axios from 'axios';
import { generateHttpActions } from '../../../../utility';

function trialListFilterDataHttpRequest(
  dataTable,
  exclusiveFilter,
  filters = {},
  playlist = '',
) {
  const httpActions = generateHttpActions('trialListFilters');
  const resource = 'trial-management/cascading-filter';
  const activeFilters =
    filters && Object.keys(filters).length > 0
      ? {
          filters: JSON.stringify(filters),
          exclusive: JSON.stringify(exclusiveFilter),
          dataTable,
          playlist,
        }
      : { exclusive: JSON.stringify(exclusiveFilter), dataTable, playlist };

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource, { params: activeFilters });

      dispatch(httpActions.success(response.data));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default trialListFilterDataHttpRequest;
