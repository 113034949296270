import axios from 'axios';

async function mySiteHealthDeletePlaylistHttpRequest(params) {
  const resource = 'site-health/playlist';

  try {
    const response = await axios.delete(resource, { params });
    return response;
  } catch (error) {
    return error.message;
  }
}

export default mySiteHealthDeletePlaylistHttpRequest;
