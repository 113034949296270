export const TIMELINE_ITEMS = {
  BASELINED: 'Baselined plan',
  PLANNED: 'Current plan',
  ACTUAL_AND_FORECAST: 'Actual / forecast',
};

export const TIMELINE_TYPE = {
  SIMPLE: 'simple',
  COMPLEX: 'complex',
};

export const TEMP = null;
