import { get, orderBy } from 'lodash';

function crfSortTransform(tableData) {
  // Transform table data, sorting acc sitename
  return orderBy(
    tableData,
    (tableRow) => get(tableRow, 'siteName', null),
    'asc',
  );
}

export default crfSortTransform;
