import { aqua, gray3, gray4 } from 'assets/stylesheets/base/_colors';
import Generator from './generator';

export default class EnrolmentCurveGenerator extends Generator {
  static _lineChartConfig = {
    yAxis: 0,
    type: 'line',
    dashStyle: null,
    showInLegend: true,
    enableMouseTracking: true,
    customTags: ['enrollment'],
  };

  getEnrollmentSeriesData(objectArray) {
    const forecastedKey = 'forecastEnrollmentMidQuantile';
    const baselinedPlanKey = 'origPlanEnrolledCum';
    const currentPlanKey = 'currPlanEnrolledCum';
    const actualPlanKey = 'actualEnrolledCum';

    const enrolmentSeriesData = {
      baselinedPlan: this._getSeriesData(objectArray, baselinedPlanKey),
      currentPlan: this._getSeriesData(objectArray, currentPlanKey),
      actual: this._getSeriesData(objectArray, actualPlanKey),
      forecast: this._getSeriesData(objectArray, forecastedKey),
    };

    return enrolmentSeriesData;
  }

  getSeries(objectArray) {
    const lineSeriesData = this.getEnrollmentSeriesData(objectArray);

    const baselinedSeries = {
      id: 'baselinedEnrollment',
      name: 'Enrollment (Baselined Plan)',
      data: lineSeriesData.baselinedPlan,
      color: gray4,
      marker: { enabled: lineSeriesData.baselinedPlan.length === 1 },
      ...EnrolmentCurveGenerator._lineChartConfig,
    };

    const plannedSeries = {
      id: 'plannedEnrollment',
      name: 'Enrollment (Current Plan)',
      data: lineSeriesData.currentPlan,
      color: gray3,
      marker: { enabled: lineSeriesData.currentPlan.length === 1 },
      ...EnrolmentCurveGenerator._lineChartConfig,
    };

    const actualAndForecastedSeries = {
      id: 'actualEnrollment',
      name: 'Enrollment (Actual)',
      data: lineSeriesData.actual,
      color: aqua,
      marker: { enabled: lineSeriesData.actual.length === 1 },
      ...EnrolmentCurveGenerator._lineChartConfig,
    };

    const enrolmentSeries = [
      baselinedSeries,
      plannedSeries,
      actualAndForecastedSeries,
    ];

    return enrolmentSeries;
  }
}
