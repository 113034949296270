import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const SiteHealthFiltersHttpRequest = (() => {
  const apiName = 'siteHealthFilters';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  // generatedHttpThunk.resource = `site-health/site-filters`;
  generatedHttpThunk.resource = `trial-management/cascading-filter`;
  return generatedHttpThunk.generatePortfolioThunk();
})();

export default SiteHealthFiltersHttpRequest;
