import { GLOBAL_FILTER_SECTION_ID } from 'constants/filters/filters.constants';
import { get } from 'lodash';
import generateHash from 'utility/hash';

export const globalFilterSelector = (currentState) => {
  const preferences = get(currentState, 'uiState.preferencesConfigMap', {});
  const globalFiltersObject = get(preferences, GLOBAL_FILTER_SECTION_ID, {});
  const globalActiveFilters = get(
    globalFiltersObject,
    'GLOBAL_FILTER_VALUE.activeFilters',
    {},
  );
  const globalSearchCountry = get(
    globalFiltersObject,
    'GLOBAL_FILTER_VALUE.seachCountryOnly',
    null,
  );
  const globalSearch = get(
    globalFiltersObject,
    'GLOBAL_FILTER_VALUE.search',
    null,
  );

  const filterUserTrials = get(
    globalFiltersObject,
    'GLOBAL_FILTER_VALUE.filterUserTrials',
    false,
  );

  const filterExclusive = get(
    globalFiltersObject,
    'GLOBAL_FILTER_VALUE.exclusive',
    false,
  );

  const filterApiParams = {
    filters: JSON.stringify({
      ...globalActiveFilters,
      exclusive: filterExclusive,
    }),
    filterUserTrials,
  };

  if (globalSearch) {
    filterApiParams.search = globalSearch;
  }
  if (globalSearchCountry) {
    filterApiParams.seachCountryOnly = globalSearchCountry;
  }
  const payloadHash = generateHash(JSON.stringify(filterApiParams));

  const returnGlobalFilterObject = {
    globalActiveFilters,
    filterUserTrials,
    filterApiParams,
    payloadHash,
  };

  return returnGlobalFilterObject;
};

export const TBD = null;
